import Link from 'next/link';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  convertToCurrencyFormat,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ViewAllPaymentPendingExpiredUserEventCard = ({
  dateStyle = 'w-[64px] h-[64px]',
  gap = 'gap-3',
  hit: {
    entityId,
    eventDate,
    userEventName,
    userEventNumber,
    userEventPaymentDueAmount,
    userEventPaymentPendingOrderCount
  }
}) => {
  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    },
    {
      target: `event-number-${entityId}`,
      text: 'Event Number'
    },
    {
      target: `amount-due-${entityId}`,
      text: 'Amount Due'
    }
  ];

  const paymentPendingExpiredUserEventCardDetails = [
    {
      alt: 'user event brand icon',
      className: 'shadow-card py-1 px-1',
      icon: 'user-event-brand-icon.svg',
      id: `event-number-${entityId}`,
      label: parseNumberedLabel(userEventNumber)
    },
    {
      alt: 'price',
      icon: 'price.svg',
      className: 'shadow-card py-1 px-1',
      id: `amount-due-${entityId}`,
      label: convertToCurrencyFormat({
        value: parseFormatPriceValueFromAPI(userEventPaymentDueAmount)
      })
    }
  ];

  return (
    <Link
      className='w-full md:ml-[17rem]'
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: entityId }
      })}
      target='_blank'
    >
      <div className='flex flex-col md:flex-row gap-3 border border-neutral hover:border-brand bg-white shadow-navigate-cart rounded-lg p-3'>
        <div className='flex items-center gap-2'>
          <Text
            {...{
              className: `bg-[#F26E271F] text-xs md:text-base py-1 md:py-0 text-brand font-semibold px-2 rounded flex text-center items-center ${dateStyle}`,
              content: getFormattedDate({ date: eventDate, format: 'Do MMM' }),
              id: `event-date-${entityId}`
            }}
          />
          <Text
            {...{
              className: 'font-medium text-sm md:text-base flex md:hidden',
              content: userEventName
            }}
          />
        </div>
        <div className={`flex flex-col flex-1 ${gap}`}>
          <div className='md:flex justify-between items-center hidden'>
            <Text
              {...{
                className: 'font-medium text-sm',
                content: userEventName,
                id: `event-title-${entityId}`
              }}
            />
            <TextKeyValuePair
              {...{
                className:
                  'text-[10px] md:text-sm font-medium rounded-lg py-[2px] md:py-1 px-1 md:px-2 shadow-card',
                label: userEventPaymentPendingOrderCount,
                labelClass:
                  'bg-brand-gradient text-white rounded-full min-w-5 h-5 text-xs md:text-sm px-1 items-center font-medium justify-center flex',
                spaceTop: '',
                value: 'Orders',
                valueClassName:
                  'text-[10px] md:text-sm font-medium text-dim-gray'
              }}
            />
          </div>
          <div className='flex justify-between'>
            <div className='flex gap-4'>
              {paymentPendingExpiredUserEventCardDetails.map(
                (paymentPendingExpiredUserEventCardDetail, index) => (
                  <TextWithIcon
                    key={`${paymentPendingExpiredUserEventCardDetail.id} _${index}`}
                    {...{
                      ...paymentPendingExpiredUserEventCardDetail,
                      iconHeight: 16,
                      iconWidth: 16,
                      labelStyle:
                        'text-[10px] md:text-xs font-medium rounded-lg text-dim-gray'
                    }}
                  />
                )
              )}
              <TextKeyValuePair
                {...{
                  className:
                    'text-[10px] md:text-sm font-medium rounded-lg py-[2px] md:py-1 px-1 md:px-2 shadow-card md:hidden',
                  label: userEventPaymentPendingOrderCount,
                  labelClass:
                    'bg-brand-gradient text-white rounded-full min-w-5 h-5 text-xs md:text-sm px-1 items-center font-medium justify-center flex',
                  spaceTop: '',
                  value: 'Orders',
                  valueClassName:
                    'text-[10px] md:text-sm font-medium text-dim-gray'
                }}
              />
            </div>
          </div>
        </div>
        <Tooltip {...{ configList: tooltipConfigList }} />
      </div>
    </Link>
  );
};

export default ViewAllPaymentPendingExpiredUserEventCard;
