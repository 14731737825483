import moment from 'moment';

const convertToDateFormat = (date, format) => moment.utc(date).format(format); // TODO: check if we should use utc here

export const formatTimeToAmPm = (time) =>
  time && moment(time, 'HH:mm').format('hh:mm A');

export const convertToShortMonthDateFormat = (date) =>
  convertToDateFormat(date, 'Do MMM YYYY');

export const convertToDateTimeString = ({ date, time }) => {
  const dateStr = date ? convertToShortMonthDateFormat(date) : '';
  const timeStr = time ? formatTimeToAmPm(time) : '';
  return [dateStr, timeStr].filter((str) => str).join(', ');
};

export const getFormattedDate = ({ date, format }) =>
  moment(date).format(format);

export const subtractDaysFromDate = ({ date, daysToSubtract }) =>
  date && moment(date).subtract(daysToSubtract, 'days');

export const parseDateWithMoment = (date) => moment(date);

export const convertToMonthYearFormat = ({ dateTimeString }) =>
  moment(dateTimeString).format('MMM YYYY');

export const isDateBeforeToday = ({ date }) =>
  moment(date).isBefore(moment(), 'day');

export const getNativeDateObject = ({ dateString }) =>
  moment(dateString).toDate();

export const getUnixTimestampForOffsetDate = ({
  dateOffset,
  endOfDay = false
}) => {
  const date = moment();
  date.add(dateOffset, 'days');

  if (endOfDay) {
    date.endOf('day');
  } else {
    date.startOf('day');
  }

  return date.unix();
};

export const getUnixTimestampFromDate = (date) =>
  // eslint-disable-next-line newline-per-chained-call
  moment(date).startOf('day').unix();

export const getFormattedDateFromUnixTimestamp = ({
  unixTimestamp,
  dateFormat
}) => moment.unix(unixTimestamp).format(dateFormat);

export const convertToShortMonthWithTimeFormat = (date) =>
  moment(date).format('Do MMM YYYY, hh.mm A');
