import _ from 'lodash';
import { useMenu } from 'react-instantsearch';

import { Text } from '@/components/atomic/nuclei';
import { isEmptyOrNull } from '@/lib/utils';

const handleTabMenuClick = ({
  defaultTabCategory,
  key,
  refine,
  setSelectedTabMenu
}) => {
  setSelectedTabMenu((prevSelectedValue) => {
    const unSelectActiveTabCategory = prevSelectedValue === key;
    return unSelectActiveTabCategory ? defaultTabCategory : key;
  });
  refine(key);
};

const ExclusiveTabsMenu = ({
  defaultTabCategory,
  refine,
  setSelectedTabMenu,
  showTabMenuCount,
  sortedItems,
  tabCountStyle,
  tabStyle
}) =>
  sortedItems.map(({ count, isRefined, label, value }, index) => (
    <li
      className={`${tabStyle} ${
        isRefined
          ? 'bg-brand-gradient text-white px-2 py-1  rounded-lg expired-left-nav'
          : ''
      }`}
      key={`${value}_${index}`}
      onClick={() =>
        !isRefined &&
        handleTabMenuClick({
          defaultTabCategory,
          key: value,
          refine,
          setSelectedTabMenu
        })
      }
    >
      <div className='flex'>
        <Text
          {...{
            className: 'ais-Menu-label',
            content: label
          }}
        />

        {showTabMenuCount && (
          <Text
            {...{
              className: `tab-menu-count ${tabCountStyle} ${
                isRefined ? 'selected-count' : ''
              }`,
              content: count,
              HtmlTag: 'span'
            }}
          />
        )}
      </div>
    </li>
  ));

const AllTabMenu = ({
  allTabMenuConfig,
  allTabMenuCount,
  isExclusiveTabSelected,
  onAllTabMenuClick,
  selectedTabMenu,
  showTabMenuCount,
  tabCountStyle,
  tabStyle
}) => {
  const { key, label } = allTabMenuConfig;
  const isAllTabMenuSelected =
    _.isNull(selectedTabMenu) || selectedTabMenu === key;

  return (
    <li
      className={`${tabStyle} ${
        isExclusiveTabSelected
          ? ''
          : 'bg-brand-gradient text-white px-2 py-1  rounded-lg expired-left-nav'
      } `}
      key={key}
      onClick={onAllTabMenuClick}
    >
      <div
        {...{
          className: `${isAllTabMenuSelected ? 'gap-2' : 'gap-0'} flex`
        }}
      >
        <Text
          {...{
            className: 'ais-Menu-label',
            content: label
          }}
        />
        {showTabMenuCount && (
          <Text
            {...{
              className: `tab-menu-count ${tabCountStyle} ${
                isAllTabMenuSelected ? 'selected-count' : ''
              }`,
              content: allTabMenuCount,
              HtmlTag: 'span'
            }}
          />
        )}
      </div>
    </li>
  );
};

const DynamicTabMenu = ({
  allTabMenuConfig = {},
  attribute,
  containerMenuListStyle,
  defaultTabCategory,
  menuFilterLimit,
  menuListStyle,
  selectedTabMenu,
  setSelectedTabMenu,
  showTabMenuCount = false,
  sortFunction = () => {},
  tabCountStyle = `ais-Menu-count searchkit-menu-list-count text-xs font-semibold rounded-full flex justify-center items-center align-middle bg-brand-gradient text-dim-gray border-none min-w-5 min-h-5`,
  tabStyle
}) => {
  const { items, refine } = useMenu({ attribute, limit: menuFilterLimit });

  const sortedItems = items.sort(sortFunction);

  const isExclusiveTabSelected = items.some(({ isRefined }) => isRefined);

  const allTabMenuCount = sortedItems.reduce(
    (accumulator, { count }) => accumulator + count,
    0
  );

  const onAllTabMenuClick = () => {
    if (isExclusiveTabSelected) {
      refine('');
      setSelectedTabMenu(allTabMenuConfig.key);
    }
  };

  return (
    <div className={containerMenuListStyle}>
      <ul className={menuListStyle}>
        {!isEmptyOrNull(allTabMenuConfig) && (
          <AllTabMenu
            {...{
              allTabMenuConfig,
              allTabMenuCount,
              isExclusiveTabSelected,
              onAllTabMenuClick,
              selectedTabMenu,
              showTabMenuCount,
              tabCountStyle,
              tabStyle
            }}
          />
        )}
        <ExclusiveTabsMenu
          {...{
            defaultTabCategory,
            refine,
            setSelectedTabMenu,
            showTabMenuCount,
            sortedItems,
            tabCountStyle,
            tabStyle
          }}
        />
      </ul>
    </div>
  );
};

export default DynamicTabMenu;
