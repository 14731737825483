import { TextKeyValuePair, TextWithIcon } from '@/components/atomic/nuclei';

const ViewAllCTA = ({
  className = 'cursor-pointer shadow-card py-1 px-2 rounded hidden md:flex',
  onClick = () => {}
}) => (
  <TextWithIcon
    {...{
      className,
      iconSuffix: {
        alt: 'arrow icon',
        icon: 'angel-right-icon.svg',
        iconHeight: 10,
        iconStyle: '',
        iconWidth: 10
      },
      label: 'View All',
      labelStyle: 'text-sm text-dim-gray font-medium',
      onClick
    }}
  />
);

const SectionTitle = ({ count = 0, title, viewAllCTAProps = {} }) => (
  <>
    <div className='flex justify-between items-center'>
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: title,
          labelClass: 'text-sm md:text-base font-semibold text-dim-gray',
          value: count,
          valueClassName:
            'bg-brand-gradient text-white rounded-full min-w-6 h-6 text-xs md:text-sm px-1 items-center font-medium justify-center flex'
        }}
      />
    </div>
    <ViewAllCTA {...viewAllCTAProps} />
  </>
);

export default SectionTitle;
