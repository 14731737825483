import Image from 'next/image';
import Link from 'next/link';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EPICEnhancedProgess = ({ entityId, epicEnhancedProgressPercentage }) => {
  const tooltipConfigList = [
    {
      target: `epic-enhanced-percentage-${entityId}`,
      text: (
        <TextKeyValuePair
          {...{
            className: '',
            label: 'EPIC Enhanced',
            labelClass: '',
            spaceTop: '',
            value: `${epicEnhancedProgressPercentage} %`,
            valueClassName: 'bg-warning-dark px-1'
          }}
        />
      )
    }
  ];
  return (
    <>
      <Image
        alt='epic enhancement progress yellow icon'
        className='cursor-pointer'
        height={0}
        id={`epic-enhanced-percentage-${entityId}`}
        src={`${staticMediaStoreBaseURL}/icons/epic-enhancement-progress-yellow.svg`}
        style={{ height: 24, width: 24 }}
        width={0}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </>
  );
};

const ActionCenterUserEventCardMobile = ({
  hit: {
    entityId,
    epicEnhancedProgressPercentage,
    eventCity,
    eventDate,
    hostCreditEntityName,
    hostMobile,
    userEventName,
    userEventNumber,
    zendeskTicketNumber
  }
}) => {
  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    },

    {
      target: `host-mobile-number-${entityId}`,
      text: 'Host Mobile Number'
    },
    {
      target: `event-city-${entityId}`,
      text: 'Event Location(City)'
    },

    {
      target: `event-number-${entityId}`,
      text: 'Event Number'
    }
  ];

  const userEventCardDetails = [
    {
      icon: 'call-with-frame.svg',
      id: `host-mobile-number-${entityId}`,
      label: convertToStandardMobileNumberFormat(hostMobile)
    },
    {
      icon: 'location-icon-with-frame.svg',
      id: `event-city-${entityId}`,
      label: eventCity
    }
  ];

  const showEpicEnhancedProgress = epicEnhancedProgressPercentage < 100;

  return (
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: entityId }
      })}
      target='_blank'
    >
      <div className='flex flex-col border border-neutral hover:border-brand shadow-navigate-cart rounded-lg pl-2'>
        <div className='flex justify-between'>
          <TextWithIcon
            {...{
              alt: 'zendesk',
              className: 'shadow-none py-1',
              icon: 'zendesk-with-frame.svg',
              iconHeight: 12,
              iconWidth: 12,
              label: parseNumberedLabel(zendeskTicketNumber),
              labelStyle: 'text-[10px] font-light text-nero'
            }}
          />
          <TextWithIcon
            {...{
              alt: 'user event white icon',
              className:
                'shadow-none bg-brand-gradient py-1 px-2 rounded-bl-lg rounded-tr-lg',
              icon: 'user-event-white-icon.svg',
              iconHeight: 12,
              iconWidth: 12,
              id: `event-number-${entityId}`,
              label: parseNumberedLabel(userEventNumber),
              labelStyle: 'text-[10px] font-medium text-white leading-3'
            }}
          />
        </div>
        <div className='flex gap-2 items-center'>
          <Text
            {...{
              className:
                'bg-brand-gradient text-white font-semibold w-10 h-10 rounded flex text-center items-center mb-1 p-1 text-xs',
              content: getFormattedDate({ date: eventDate, format: 'Do MMM' }),
              id: `event-date-${entityId}`
            }}
          />
          <Text
            {...{
              className: 'font-medium text-xs',
              content: userEventName,
              id: `event-title-${entityId}`
            }}
          />
        </div>
        <div className='pb-2'>
          <div className='flex flex-col gap-0'>
            <div className='flex justify-between pr-2 gap-2'>
              {userEventCardDetails.map((userEventCardDetail, index) => (
                <TextWithIcon
                  key={`${userEventCardDetail.id} _${index}`}
                  {...{
                    ...userEventCardDetail,
                    className: 'shadow-none',
                    iconHeight: 12,
                    iconWidth: 12,
                    labelStyle: 'text-[10px] font-light text-nero'
                  }}
                />
              ))}
            </div>
          </div>

          <TextWithIcon
            {...{
              alt: 'host credit entity icon',
              className: 'shadow-none',
              icon: 'host-credit-entity-red-icon.svg',
              iconHeight: 12,
              iconWidth: 12,
              label: hostCreditEntityName,
              labelStyle: 'text-[10px] font-light text-nero'
            }}
          />
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </Link>
  );
};

export default ActionCenterUserEventCardMobile;
