import Image from 'next/image';
import Link from 'next/link';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EPICEnhancedProgess = ({ entityId, epicEnhancedProgressPercentage }) => {
  const tooltipConfigList = [
    {
      target: `epic-enhanced-percentage-${entityId}`,
      text: (
        <TextKeyValuePair
          {...{
            className: '',
            label: 'EPIC Enhanced',
            labelClass: '',
            spaceTop: '',
            value: `${epicEnhancedProgressPercentage} %`,
            valueClassName: 'bg-warning-dark px-1'
          }}
        />
      )
    }
  ];
  return (
    <>
      <Image
        alt='epic enhancement progress yellow icon'
        className='cursor-pointer'
        height={0}
        id={`epic-enhanced-percentage-${entityId}`}
        src={`${staticMediaStoreBaseURL}/icons/epic-enhancement-progress-yellow.svg`}
        style={{ height: 24, width: 24 }}
        width={0}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </>
  );
};

const ActionCenterUserEventCardWeb = ({
  hit: {
    entityId,
    epicEnhancedProgressPercentage,
    eventCity,
    eventDate,
    hostCreditEntityName,
    hostMobile,
    userEventName,
    userEventNumber,
    zendeskTicketNumber
  }
}) => {
  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    },
    {
      target: `zendesk-ticket-number-${entityId}`,
      text: 'Zendesk Ticket Number'
    },
    {
      target: `host-mobile-number-${entityId}`,
      text: 'Host Mobile Number'
    },
    {
      target: `event-city-${entityId}`,
      text: 'Event Location(City)'
    },
    {
      target: `host-credit-entity-name-${entityId}`,
      text: 'Credit Entity Name'
    },
    {
      target: `event-number-${entityId}`,
      text: 'Event Number'
    }
  ];

  const userEventCardDetails = [
    {
      icon: 'zendesk-with-frame.svg',
      id: `zendesk-ticket-number-${entityId}`,
      label: parseNumberedLabel(zendeskTicketNumber)
    },
    {
      icon: 'call-with-frame.svg',
      id: `host-mobile-number-${entityId}`,
      label: convertToStandardMobileNumberFormat(hostMobile)
    },
    {
      icon: 'location-icon-with-frame.svg',
      id: `event-city-${entityId}`,
      label: eventCity
    },
    {
      icon: 'host-credit-entity-red-icon.svg',
      id: `host-credit-entity-name-${entityId}`,
      label: hostCreditEntityName
    }
  ];

  const showEpicEnhancedProgress = epicEnhancedProgressPercentage < 100;

  return (
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: entityId }
      })}
      target='_blank'
    >
      <div className='flex gap-2 border border-neutral hover:border-brand shadow-navigate-cart rounded-lg pl-2'>
        <Text
          {...{
            className:
              'bg-brand-gradient text-white font-semibold w-14 h-14 rounded flex text-center items-center my-2 p-2',
            content: getFormattedDate({ date: eventDate, format: 'Do MMM' }),
            id: `event-date-${entityId}`
          }}
        />
        <div className='flex flex-col gap-2 py-2'>
          <Text
            {...{
              className: 'font-medium truncate',
              content: userEventName,
              id: `event-title-${entityId}`
            }}
          />
          <div className='flex gap-4'>
            {userEventCardDetails.map((userEventCardDetail, index) => (
              <TextWithIcon
                key={`${userEventCardDetail.id} _${index}`}
                {...{
                  ...userEventCardDetail,
                  className: 'shadow-none',
                  iconHeight: 20,
                  iconWidth: 20,
                  labelStyle: 'text-sm font-light'
                }}
              />
            ))}
          </div>
        </div>
        <div className='flex gap-4 ml-auto self-start items-baseline'>
          {showEpicEnhancedProgress && (
            <EPICEnhancedProgess
              {...{ entityId, epicEnhancedProgressPercentage }}
            />
          )}
          <TextWithIcon
            {...{
              alt: 'user event white icon',
              className:
                'shadow-none bg-brand-gradient py-1 px-3 rounded-bl-lg rounded-tr-lg',
              icon: 'user-event-white-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              id: `event-number-${entityId}`,
              label: parseNumberedLabel(userEventNumber),
              labelStyle: 'text-sm font-medium text-white'
            }}
          />
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </Link>
  );
};

export default ActionCenterUserEventCardWeb;
