// TODO: recheck (wherever getHostAPIURL is used) secured+public APIs from this file; change in FE and in BE, use ops or planner_app API wherever feasible

import {
  HOST_API_BASE_URL,
  OPS_API_BASE_URL,
  PLANNER_API_BASE_URL
} from '@/services/connections.service';

const interpolatePath = ({ pathname, pathParams }) =>
  Object.entries(pathParams).reduce(
    (template, [key, value]) => template.replace(`:${key}`, value ?? ''),
    pathname
  );

const buildQueryString = ({ queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : '';
};

export const getURLPath = ({ pathname, pathParams = {}, queryParams = {} }) => {
  const interpolatedPath = interpolatePath({ pathname, pathParams });
  const queryString = buildQueryString({ queryParams });
  return `${interpolatedPath}${queryString}`;
};

const getAPIURL = ({
  baseUrl,
  pathname,
  pathParams = {},
  queryParams = {}
}) => {
  const path = getURLPath({ pathname, pathParams, queryParams });
  const url = new URL(`${baseUrl}${path}`);
  return url.toString();
};

const getOpsAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: OPS_API_BASE_URL, pathname, pathParams });

const getPlannerAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: PLANNER_API_BASE_URL, pathname, pathParams });

const getHostAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: HOST_API_BASE_URL, pathname, pathParams });

export const GET_CITY_DETAILS_API_URL = getHostAPIURL('/cities');

export const GET_EVENT_SITE_TYPE_LIST_API_URL =
  getHostAPIURL('/event-site-types');

export const GET_CHECKOUT_EVENT_LOOKUP_API_URL = getHostAPIURL(
  '/checkout/eventType'
);

export const GET_LEAD_EXIT_REASONS_API_URL =
  getPlannerAPIURL('/lead-exit-reasons');

export const GET_CONTACT_METHODS_API_URL = getPlannerAPIURL('/contact-methods');

export const GET_EVENT_VERTICALS_API_URL = getPlannerAPIURL('/event-verticals');

export const CREATE_TENDER_AND_DISPATCH_BIDS_API_URL = () =>
  getPlannerAPIURL('/tenders');

export const SEARCHKIT_PRODUCT_SEARCH_API_URL =
  getPlannerAPIURL('/product-search');

export const SEARCH_KIT_NAVIGATION_SEARCH_API_URL = getPlannerAPIURL(
  '/planner-navigation/search'
);

export const SEARCH_KIT_ORDERS_SEARCH_API_URL = getPlannerAPIURL('/order-list');

export const SEARCHKIT_EVENT_PIPELINE_API_URL = getPlannerAPIURL(
  '/search/event-pipeline'
);

export const POST_METABASE_DASHBOARD_SIGNED_API_URL = () =>
  getOpsAPIURL('/mb/generate-signed-url');

export const GET_TENDER_RESPONSE_API_URL = ({ referenceId }) =>
  getPlannerAPIURL('/tenders/responses/:referenceId', { referenceId });

export const GET_READ_ONLY_CART_DETAILS_API_URL = ({ cartId }) =>
  getPlannerAPIURL('/carts/:cartId/read-only-details', {
    cartId
  });

export const GET_ORDER_INVOICE_API_URL = ({ orderId }) =>
  getOpsAPIURL('/orders/:orderId/invoice', { orderId });

export const GET_ORDER_DETAILS_API_URL = ({ orderId }) =>
  getPlannerAPIURL('/orders/:orderId', {
    orderId
  });

export const UPDATE_USER_EVENT_API_URL = (userId, userEventId) =>
  getPlannerAPIURL(
    '/users/:userId/user-events/:userEventId/update-user-event-profile',
    {
      userId,
      userEventId
    }
  );

export const GET_USER_EVENT_CART_DETAILS_API_URL = ({ userEventId }) =>
  getPlannerAPIURL('/user-events/:userEventId/event-profile-and-cart-list', {
    userEventId
  });

export const GET_ORDER_MEDIA_BY_EVENT_ID_API_URL = ({ userEventId }) =>
  getPlannerAPIURL('/user-events/:userEventId/orderMedia', {
    userEventId
  });

export const UPDATE_ARCHIVED_CART_STATUS_API_URL = ({ userCartId, cartId }) =>
  getPlannerAPIURL('/users/:userCartId/carts/:cartId/isArchived', {
    cartId,
    userCartId
  });

export const UPDATE_CART_INFO_API_URL = ({ userCartId, cartId }) =>
  getPlannerAPIURL('/users/:userCartId/carts/:cartId', { userCartId, cartId });

export const CLONE_NON_ORDERED_CART_API_URL = ({ userCartId, cartId }) =>
  getPlannerAPIURL('/users/:userCartId/carts/:cartId/clone', {
    userCartId,
    cartId
  });

export const CLONE_ORDERED_CART_API_URL = ({ userCartId, cartId }) =>
  getPlannerAPIURL('/users/:userCartId/carts/:cartId/clone-ordered-cart', {
    userCartId,
    cartId
  });

export const REVISE_TENDER_AND_DISPATCH_BIDS_API_URL = ({ referenceId }) =>
  getPlannerAPIURL('/tenders/revise/:referenceId', { referenceId });

export const GET_CART_DETAILS_API_URL = ({ cartId }) =>
  getPlannerAPIURL('/carts/:cartId/details', { cartId });

export const DELETE_ITEM_FROM_CART_API_URL = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getPlannerAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    cartId,
    cartItemId,
    userCartId
  });

export const CLONE_CART_ITEM_API_URL = ({ cartId, cartItemId, userCartId }) =>
  getPlannerAPIURL(
    '/users/:userCartId/carts/:cartId/cart-item/:cartItemId/clone',
    {
      cartId,
      cartItemId,
      userCartId
    }
  );

export const UPDATE_CART_ITEM_API_URL = ({ userCartId, cartId, cartItemId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    userCartId,
    cartId,
    cartItemId
  });

export const DELETE_CART_ITEM_MEDIA_API_URL = ({
  cartId,
  cartItemId,
  cartItemMediaId
}) =>
  getPlannerAPIURL(
    '/cart/:cartId/cart-item/:cartItemId/cart-item-media/:cartItemMediaId',
    { cartId, cartItemId, cartItemMediaId }
  );

export const UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA_API_URL = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getPlannerAPIURL(
    '/users/:userCartId/carts/:cartId/cart-item/:cartItemId/showProductMedia',
    {
      cartId,
      cartItemId,
      userCartId
    }
  );

export const RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT_API_URL = () =>
  getPlannerAPIURL('/cart-item-media/reconcile');

export const GET_IMAGEKIT_AUTH_API_URL = () => getOpsAPIURL('/imagekit/auth');

export const UPDATE_CART_ITEM_SORT_ORDER_API_URL = ({ cartId }) =>
  getPlannerAPIURL('/cart/:cartId/cart-item/update-sort-order', { cartId });

export const UPDATE_CART_ITEM_MEDIA_SORT_ORDER_API_URL = ({
  cartId,
  cartItemId
}) =>
  getPlannerAPIURL(
    '/cart/:cartId/cart-item/:cartItemId/cart-item-media/update-sort-order',
    { cartId, cartItemId }
  );

export const ADD_NEW_CART_API_URL = ({ userId }) =>
  getPlannerAPIURL('/users/:userId/cart', { userId });

export const GET_CART_QUOTATION_API_URL = ({ userCartId, cartId }) =>
  getHostAPIURL('/user/:userCartId/carts/:cartId/quote-pdf', {
    userCartId,
    cartId
  });

export const EVENT_CART_CHECKOUT_API_URL = ({ cartId, userCartId }) =>
  getPlannerAPIURL(
    '/users/:userCartId/carts/:cartId/checkout-non-default-cart',
    {
      cartId,
      userCartId
    }
  );

export const ADMIN_GOOGLE_LOGIN_API_URL = () =>
  getOpsAPIURL('/auth/google?state=admin');

export const VERIFY_CART_NUMBER_FOR_ADD_ITEM_API_URL = ({ cartNumber }) =>
  getPlannerAPIURL('/carts/:cartNumber/verify-cart-by-number-for-add-item', {
    cartNumber
  });

export const ADD_TO_CART_API_URL = ({ cartId, userCartId }) =>
  getPlannerAPIURL(`/users/${userCartId}/carts/${cartId}/cart-item`, {
    cartId,
    userCartId
  });

export const GET_HOST_PROFILE_AND_USER_EVENTS_API_URL = ({ userId }) =>
  getPlannerAPIURL('/users/:userId/host-event-list', { userId });

export const GET_HOST_LIST_API_URL = ({ filters, limit, skip }) =>
  getPlannerAPIURL('/hosts?skip=:skip&limit=:limit&filters=:filters', {
    filters,
    limit,
    skip
  });

export const ADD_HOST_API_URL = () => getPlannerAPIURL('/customers');

export const UPDATE_HOST_API_URL = ({ hostId }) =>
  getPlannerAPIURL('/customers/:customerId', { customerId: hostId });

export const GET_HOST_PROFILE_WITH_STATS_API_URL = ({ hostId }) =>
  getPlannerAPIURL('/users/:hostId', { hostId });

export const VERIFY_CART_NUMBER_EXISTENCE_API_API_URL = ({ cartNumber }) =>
  getPlannerAPIURL('/carts/:cartNumber/verify-if-exists-by-cart-number', {
    cartNumber
  });

export const CLONE_CART_TO_USER_EVENT_API_URL = () =>
  getPlannerAPIURL('/carts/clone-cart-to-user-event');

export const GET_HOST_SEGMENTS_API_URL = () => getOpsAPIURL('/host-segments');

export const VERIFY_EVENT_NUMBER_EXISTENCE_API_URL = ({ userEventNumber }) =>
  getPlannerAPIURL('/user-event/:userEventNumber/verify-if-exists-by-number', {
    userEventNumber
  });
export const GET_LANGUAGES_API_URL = () => getPlannerAPIURL('/languages');

export const GET_ETHNICITIES_API_URL = () => getPlannerAPIURL('/ethnicities');

export const DELETE_ORDER_ITEM_MEDIA_API_URL = ({
  orderId,
  orderItemId,
  orderItemMediaId
}) =>
  getPlannerAPIURL(
    '/order/:orderId/order-item/:orderItemId/order-item-media/:orderItemMediaId',
    { orderId, orderItemId, orderItemMediaId }
  );

export const RECONCILE_ORDER_ITEM_MEDIA_FROM_IMAGEKIT_API_URL = () =>
  getPlannerAPIURL('/order-item-media/reconcile');

export const UPDATE_ORDER_ITEM_MEDIA_SORT_ORDER_API_URL = ({
  orderId,
  orderItemId
}) =>
  getPlannerAPIURL(
    '/order/:orderId/order-item/:orderItemId/order-item-media/update-sort-order',
    { orderId, orderItemId }
  );

export const GET_HOST_CREDIT_ENTITY_HOST_LIST_API_URL = ({
  hostCreditEntityId
}) =>
  getPlannerAPIURL('/host-credit-entity/:hostCreditEntityId/host-list', {
    hostCreditEntityId
  });

export const GET_HOST_CREDIT_ENTITY_PROFILE_DETAILS_API_URL = ({
  hostCreditEntityId
}) =>
  getPlannerAPIURL('/host-credit-entity/:hostCreditEntityId', {
    hostCreditEntityId
  });

export const ADD_HOST_CREDIT_ENTITY_API_URL = () =>
  getPlannerAPIURL('/host-credit-entity');

export const GET_OPS_USER_LIST_API_URL = () => getOpsAPIURL('/opsusers');

export const GET_CREDIT_REQUEST_REASONS_API_URL = () =>
  getPlannerAPIURL('/credit-request-reasons');

export const CREATE_HOST_CREDIT_ENTITY_CREDIT_REQUEST_API_URL = ({
  hostCreditEntityId
}) =>
  getPlannerAPIURL('/host-credit-entity/:hostCreditEntityId/credit-request', {
    hostCreditEntityId
  });

export const GET_HOST_CREDIT_ENTITY_CREDIT_REQUESTS_API_URL = ({
  hostCreditEntityId
}) =>
  getPlannerAPIURL('/host-credit-entity/:hostCreditEntityId/credit-requests', {
    hostCreditEntityId
  });

export const UPDATE_HOST_CREDIT_ENTITY_CREDIT_REQUEST_API_URL = ({
  hostCreditEntityCreditRequestId
}) =>
  getPlannerAPIURL(
    '/host-credit-entity-credit-request/:hostCreditEntityCreditRequestId',
    {
      hostCreditEntityCreditRequestId
    }
  );

export const UPDATE_HOST_CREDIT_ENTITY_API_URL = ({ hostCreditEntityId }) =>
  getPlannerAPIURL('/host-credit-entity/:hostCreditEntityId', {
    hostCreditEntityId
  });

export const GET_PRODUCT_API_URL = ({ productSlug: slug }) =>
  getHostAPIURL('/products/:slug', { slug });

export const GET_UPCOMING_ASSIGNED_USER_EVENTS_API_URL = ({
  limit,
  plannerId,
  skip
}) =>
  getPlannerAPIURL(
    '/user-events/upcoming-assigned-user-events?skip=:skip&limit=:limit&plannerId=:plannerId',
    { limit, plannerId, skip }
  );

export const CREATE_EVENT_TYPEFORM_URL =
  'https://diczfllhwrf.typeform.com/to/Sac8lVxs';

export const CREATE_EVENT_PROFILE = () => getPlannerAPIURL('/user-event');

export const UPDATE_ALLOCATION_STATUS_API_URL = ({ userEventId }) =>
  getPlannerAPIURL('/user-events/:userEventId/allocation-status', {
    userEventId
  });
