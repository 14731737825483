import { useRouter } from 'next/router';

import { CloseIcon, Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { useIsMobile } from '@/lib/screenResolution';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ViewAllUserEventsPageHeader = ({ count, showCount = true, title }) => {
  const router = useRouter();

  const [isMobile] = useIsMobile();
  return (
    <div className='sticky bg-white top-0 h-14 md:h-16 pt-2 z-50 px-5 md:px-8 mt-0 shadow-navigate-cart md:shadow-none'>
      <CloseIcon
        {...{
          className: 'absolute right-4 top-3 md:top-4',
          height: 32,
          icon: 'close-red-icon.svg',
          onClick: () =>
            router.push(
              getPageURL({
                pageName: PAGE_NAME.ACTION_CENTER.label
              })
            ),
          width: 32
        }}
      />
      {showCount ? (
        <TextKeyValuePair
          {...{
            className: 'flex-row items-center gap-2',
            label: title,
            labelClass: 'text-base md:text-3xl font-semibold text-dim-gray',
            value: count,
            valueClassName:
              'bg-brand-gradient text-white rounded-full min-w-6 px-1 h-6 text-sm font-medium items-center justify-center hidden md:flex'
          }}
        />
      ) : (
        <div className='flex gap-2 flex-row items-center gap-2 mt-2 md:mt-0'>
          <Text
            {...{
              className: `text-base md:text-3xl font-semibold text-dim-gray ${
                isMobile ? 'truncate-text-one-line' : ''
              } `,
              content: title
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ViewAllUserEventsPageHeader;
